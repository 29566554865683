import React from 'react';
import styled from 'styled-components';
import {Tabs} from 'antd';
import * as Icon from '../../components/Icon';
import * as Theme from '../../domain/Theme';
import * as AppContext from '../../AppContext';
import {
  AppstoreOutlined,
  GiftOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import imgShipping1 from '../../images/product-detail-shipping-20241225.jpg';
import imgFaq1 from '../../images/product-detail-faq-1.jpeg';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';

const _TABS = {
  info: 'info',
  shipping: 'shipping',
  faq: 'faq',
};

function ProductInfoTabs(props) {
  const {product, style} = props;
  const app = React.useContext(AppContext.Context);
  const _onTabChange = (tab) => 0;

  const [introArticle, setIntroArticle] = React.useState(null);

  React.useEffect(() => {
    const _fetchData = async () => {
      try {
        let article = await app.actions.getArticleById(
          product.intro_article_id,
        );
        setIntroArticle(article);
      } catch (err) {
        //
      }
    };

    _fetchData();
  }, [product.intro_article_id]);

  return (
    <Wrapper style={style}>
      <Tabs defaultActiveKey={_TABS.info} onChange={_onTabChange}>
        <Tabs.TabPane
          className="info"
          tab={
            <span>
              <GiftOutlined />
              商品介紹
            </span>
          }
          key={_TABS.info}>
          <div className="content">
            {introArticle &&
              (introArticle.html ? (
                <div dangerouslySetInnerHTML={{__html: introArticle.html}} />
              ) : (
                <RichTextPreview content={introArticle.content || []} />
              ))}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          className="shipping"
          tab={
            <span>
              <AppstoreOutlined />
              運送說明
            </span>
          }
          key={_TABS.shipping}>
          <div className="content">
            <img src={imgShipping1} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          className="faq"
          tab={
            <span>
              <QuestionCircleOutlined />
              常見問題
            </span>
          }
          key={_TABS.faq}>
          <div className="content">
            <img src={imgFaq1} />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .content {
    max-width: 1320px;
    margin: 0 auto;
  }

  & .info {
    & > .content img {
      display: block;
      width: 100%;
    }
  }

  & .shipping {
    & > .content img {
      display: block;
      width: 100%;
    }
  }

  & .faq {
    & > .content img {
      display: block;
      width: 100%;
    }
  }
  /* overwrite antd tab styling */
  & .ant-tabs.ant-tabs-top {
    background-color: #fff;
  }

  & .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    flex: 1;

    & > .ant-tabs-tab {
      margin-right: 0px;
      background-color: #efeff4;
      flex: 1;
      justify-content: center;
    }

    & > .ant-tabs-tab.ant-tabs-tab-active {
      background-color: #fff;
    }
  }

  & .ant-tabs-content {
    padding: 0px;
    margin-top: -16px;
  }
`;

export default ProductInfoTabs;
